import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const ContactUs = (props) => {


	return (

		<div className="px-4 py-16 md:px-24 lg:px-8 lg:pt-2 lg:pb-24 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
			<div className="flex flex-col mb-6 lg:flex-row md:mb-10">
				<div className="w-full flex lg:justify-end md:justify-center justify-center ">
					<h2 className="max-w-md mb-16 mr-16 font-sans sm:text-7xl text-4xl font-bold tracking-tight text-blue-accent-700 sm:leading-none xl:max-w-lg">
						Ci trovi qui
					</h2>
				</div>
			</div>
			<div className="flex flex-col mb-6 lg:flex-row justify-end">
				{props.contacts.list.map(c =>
					<div className="mx-16">

						{c.id === "phone" ?
							<a href={`tel:${c.text}`}>
								<div className="mx-auto flex items-center justify-center w-32 h-32 mb-6 rounded-full bg-blue-accent-400">
									<FontAwesomeIcon
										icon={c.icon}
										className="pt-2 fa-solid h-14 w-14 text-white"
									/>
								</div>
							</a>
							:
							null
						}

						{c.id === "address" ?
							<a href="https://goo.gl/maps/rhjZ4d16rDTwmdkz8" target="_blank">
								<div className="mx-auto flex items-center justify-center w-32 h-32 mb-6 rounded-full bg-blue-accent-400">
									<FontAwesomeIcon
										icon={c.icon}
										className="pt-2 fa-solid h-14 w-14 text-white"
									/>
								</div>
							</a>
							:
							null
						}

						{c.id === "mail" ?
							<a href={`mailto:${c.text}`}>
								<div className="mx-auto flex items-center justify-center w-32 h-32 mb-6 rounded-full bg-blue-accent-400">
									<FontAwesomeIcon
										icon={c.icon}
										className="pt-2 fa-solid h-14 w-14 text-white"
									/>
								</div>
							</a>
							:
							null
						}



						<h6 className="mb-4 font-roboto font-bold tracking-tight leading-8 text-center text-3xl">{c.title}</h6>


						{c.id === "address" ?
							<p className="mb-3 text-xl text-gray-900 text-center">
								<a href={c.text}>
									{c.text}
								</a>
							</p> :
							null}

						{c.id === "phone" ?
							<p className="mb-3 text-xl text-gray-900 text-center">
								<a href={`tel:${c.text}`}>
									{c.text}
								</a>
							</p> :
							null}

						{c.id === "mail" ?
							<p className="mb-3 text-xl text-gray-900 text-center">
								<a href={`mailto:${c.text}`}>
									{c.text}
								</a>
							</p> :
							null}
					</div>
				)}


			</div >
		</div >


		// <div className="relative">
		//   <img
		//     src="/boat4.jpg"
		//     className="absolute inset-0 object-cover object-right w-full h-full"
		//     alt=""
		//   />
		//   <div className="relative bg-opacity-80 bg-gray-900">
		//     <div className="flex flex-col items-center justify-center max-w-2xl sm:max-w-4xl md:max-w-8xl px-4 pt-16 mx-auto lg:pt-32 md:px-8">
		//       <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
		//         <h2 className="max-w-8xl mb-6 font-sans text-6xl sm:text-8xl font-semibold leading-none tracking-tight text-teal-accent-400 md:mx-auto">
		//           Vuoi mandarci un messaggio?
		//         </h2>
		//         <p className="text-xl text-white md:text-2xl">
		//           Compila il form qui sotto per inviarci direttamente una richiesta o per chiarire qualunque dubbio!
		//         </p>
		//       </div>


		//       <form ref={form} className="w-full sm:mx-0" onSubmit={sendEmail}>
		//         <div className="mb-1 sm:mb-2">
		//           <label
		//             htmlFor="firstName"
		//             className="inline-block mb-2 font-medium text-xl text-teal-accent-400"
		//           >
		//             Nome
		//           </label>
		//           <input
		//             placeholder="Mario"
		//             required
		//             type="text"
		//             className="text-xl flex-grow w-full h-14 px-4 mb-2 transition duration-500 bg-gray-600  focus:bg-gray-900 text-white border-gray-300 rounded shadow-sm appearance-none border-4 focus:border-teal-accent-400 focus:outline-none focus:shadow-outline"
		//             id="firstName"
		//             name="first_name"
		//           />
		//         </div>
		//         <div className="mb-1 sm:mb-2">
		//           <label
		//             htmlFor="lastName"
		//             className="inline-block mb-2 font-medium text-xl text-teal-accent-400"
		//           >
		//             Cognome
		//           </label>
		//           <input
		//             placeholder="Rossi"
		//             required
		//             type="text"
		//             className="text-xl flex-grow w-full h-14 px-4 mb-2 transition duration-500 bg-gray-600  focus:bg-gray-900 text-white border-gray-300 rounded shadow-sm appearance-none border-4 focus:border-teal-accent-400 focus:outline-none focus:shadow-outline"
		//             id="lastName"
		//             name="last_name"
		//           />
		//         </div>
		//         <div className="mb-1 sm:mb-2">
		//           <label
		//             htmlFor="email"
		//             className="inline-block mb-2 font-medium text-xl text-teal-accent-400"
		//           >
		//             E-mail
		//           </label>
		//           <input
		//             placeholder="mario.rossi@esempio.com"
		//             required
		//             type="text"
		//             className="text-xl flex-grow w-full h-14 px-4 mb-2 transition duration-500 bg-gray-600 focus:bg-gray-900 text-white border-gray-300 rounded shadow-sm appearance-none border-4 focus:border-teal-accent-400 focus:outline-none focus:shadow-outline"
		//             id="email"
		//             name="user_email"
		//           />
		//         </div>
		//         <div className="mb-1 sm:mb-2">
		//           <label
		//             htmlFor="message"
		//             className="inline-block mb-2 font-medium text-xl text-teal-accent-400"
		//           >
		//             Messaggio
		//           </label>
		//           <textarea
		//             name="message"
		//             rows="4"
		//             className="block p-2.5 shadow-sm appearance-none transition duration-400
		//           w-full text-lg  bg-gray-600 focus:bg-gray-900 text-white
		//           rounded-lg border-4 border-gray-300
		//           focus:border-teal-accent-400 focus:outline-none focus:shadow-outline"
		//             placeholder="Scrivi un messaggio">

		//           </textarea>

		//         </div>
		//         <div className="mt-4 mb-2 sm:mb-4 sm:w-1/2 w-full mx-auto">
		//           <button
		//             type="submit"
		//             className="text-2xl font-semibold font-roboto tracking-tight inline-flex items-center justify-center w-full p-4 text-teal-900 transition duration-200 rounded shadow-md bg-teal-accent-400 hover:bg-teal-accent-700 focus:shadow-outline focus:outline-none">
		//             Inviaci un messaggio!
		//           </button>
		//         </div>
		//       </form>


		//       <Link href="/" className="max-w-md mb-10 text-xs text-gray-600 sm:text-sm md:text-cente">
		//         Rispettiamo la tua privacy. Leggi i nostri termini di privacy e policy
		//       </Link>



		//     </div>
		//   </div>
		// </div>
	);
};