import React from "react";
import Layout from '../components/layout/Layout';
import { FAQ } from '../components/FAQ';
import questions from '../data/faq/doc.json'
import domande from '../data/faq/header.json';
import { HeroHighlight } from '../components/HeroHighlight';
import { ContactUs } from '../components/ContactUs';
import { contacts } from '../data/contacts/contacts';
import Seo from "../components/layout/SEO";

const DomandeFrequenti = () => {
    return (
        <Layout>
            <Seo 
            title={"Domande Frequenti | Autoscuola Faro a Pisa"}
            description={"Vuoi saperne di più su Autoscuola Faro a Pisa? Leggi tutte le informazioni qui sui nostri corsi patente, le pratiche e i rinnovi."}
            keywords={[
                "autoscuola Faro a Pisa",
                "domande frequenti Autoscuola Faro Pisa"
              ]}
            />
            <HeroHighlight
                title={domande.title}
                newLine={domande.newLine}
                highlight={domande.highlight}
                par1={domande.par1}
                par2={domande.par2}
                internalLink={domande.internalLink}
            />
            <FAQ questionList={questions} />
            <ContactUs contacts={contacts}/>
        </Layout>
    )
};

export default DomandeFrequenti;