import { faAddressBook, faEnvelope, faLocationDot, faMailForward, faPhone } from "@fortawesome/free-solid-svg-icons";

export const contacts = {
    title: "Contattaci",
    subtitle: "",
    list: [
        {
            id: "address",
            title: "",
            text: "Via Emilia, 2, 56100, Pisa",
            link: "https://goo.gl/maps/rhjZ4d16rDTwmdkz8",
            icon: faLocationDot
        }, 
        {
            id: "phone",
            title: "",
            text: "+39 050 25057",
            link: "",
            icon: faPhone
        }, 
        {
            id: "mail",
            title: "",
            text: "info@autoscuolafaro.it",
            link: "",
            icon: faEnvelope
        },
    ]
}