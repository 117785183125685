import React, { useState } from "react";
import DomandeFrequenti from '../pages/domande-frequenti';


const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="rounded border border-blue-accent-700">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-3xl text-start tracking-tighter font-normal font-roboto text-blue-accent-700">{title}</p>
        <div className="flex items-center justify-center w-8 h-8 border rounded-full">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-gray-600 transition-transform duration-400 ${isOpen ? 'transform rotate-180' : ''
              }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-gray-700 text-xl font-roboto font-semibold">{children}</p>
        </div>
      )}
    </div>
  );
};

export const FAQ = (props) => {
  return (
    <section id="faq">
      <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="max-w-xl sm:mx-auto lg:max-w-6xl">
          {/* <div class="flex flex-col mb-16 sm:text-center">
          <div class="max-w-xl md:mx-auto sm:text-center lg:max-w-2xl">
            <h2 class="max-w-lg mb-6 font-roboto text-4xl sm:text-6xl font-bold leading-none tracking-tight text-blue-accent-700 md:mx-auto">
              Domande frequenti
            </h2>
          </div>
        </div> */}
          <div class="space-y-4">
            {props.questionList.map(q => (
              <Item key={q.id} title={q.title} className="my16">
                {q.answer}
              </Item>
            ))}

          </div>
        </div>
      </div>
    </section>
  );
}